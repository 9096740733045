.unit-pricelist-v1 {

	&.showSeasons {
		.table-responsive {
			display: block;
		}
	}

	.table-responsive {
		display: none;
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}