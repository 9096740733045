.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.unit-iframe-section {
		margin-top: var(--unit-section-mb);
		margin-bottom: var(--unit-section-mb);

		iframe {
			width: 100%;
			height: 550px;
			border: none;

			@media (max-width: 1199px) {
				height: 380px;
			}


			@media (max-width: 767px) {
				height: 270px;
			}

			@media (max-width: 550px) {
				height: 230px;
			}
		}

		.unit-iframe-bl {
			padding: 70px 30px;
			background-color: #5e5e5e;

			p {
				color: var(--color-white);
			}
		}
	}


	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}

		.hightlightsprop {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 30px 0 0 0;

				li {
					width: 25%;
					padding: 0 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					margin-bottom: 30px;
					text-align: center;

					@media (max-width: 767px) {
						width: 50%;
					}

					.icon {
						display: block;
						width: 100%;
						font-size: 30px;
						text-align: center;
						color: var(--color-primary);
					}
				}
			}
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: block;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}