.static-view {
	margin-top: var(--page-margin-top);

	.service-mt-30 {
		margin-top: -100px !important;
	}
}

.juicer-section {
	.referral {
		display: none !important;
	}
}

.juicer-feed .j-paginate {

	background-color: var(--btn-primary-bg-color);
	color: var(--btn-primary-font-color);
	border-color: var(--btn-primary-border-color);
	font-size: var(--font-size-md);
	padding: 8px 12px;
	width: 100%;
	max-width: 130px;
	text-transform: capitalize;
	font-weight: 400;

	&:hover {
		background-color: var(--btn-primary-bg-color-hover);
		border-color: var(--btn-primary-border-color-hover);
		color: var(--btn-primary-font-color-hover);

	}
}