.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}

.checkbox {
	label:before {
		top: 5px !important;
	}

	label:after {
		top: 5px !important;
	}
}