.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 10px 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:1200px) {
		position: absolute;
	}

	.input-search-unit {
		padding: 0;
		max-width: 280px;
		position: relative;
		margin-left: 20px;
		padding-left: 20px;

		&:before {
			content: " ";
			width: 2px;
			height: 25px;
			background: #e1e4e6;
			display: block;
			position: absolute;
			left: 0;
			top: 4px;
		}


		@media (max-width:1200px) {
			max-width: 100%;
		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		@media (max-width: 991px) {
			margin: 0;
			padding: 0;
		}


		.form-control {
			padding: 5px 10px;
			width: 200px;
		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.header-main {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header-left {
			width: 33.33%;
			text-align: left;
			display: flex;
			align-items: center;

			@media all and (min-width: 992px) and (max-width: 1199px) {
				width: 43%;
			}

			.menu-icon {
				position: relative;
			}

		}

		.header-logo {
			width: 33.33%;
			text-align: center;
		}

		.header-right {
			width: 33.33%;
			text-align: right;

			ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 0;

				@media (max-width: 991px) {
					margin: 0;
				}

				li {
					position: relative;

					.fa.fa-phone-alt {
						margin-right: 5px;
					}

					a {
						text-decoration: none;

						@media (max-width: 1199px) {
							font-size: 0;
						}

						&:hover {
							color: var(--color-secondary);
						}
					}

					i {
						@media (max-width: 1199px) {
							font-size: 16px;
						}
					}

					&.user {
						margin-left: 15px;
						padding-left: 15px;
						padding-right: 15px;

						@media (max-width: 991px) {
							margin-left: 0;
							padding-left: 10px;
							padding-right: 10px;
						}

						@media (max-width: 767px) {
							margin-left: 0;
							padding-left: 10px;
							padding-right: 10px;
						}

						a {
							position: relative;
							padding: 5px 10px;
							font-size: 14px;

							@media (max-width: 767px) {
								font-size: 0;
								background: transparent;
								border: none;
								padding: 0;
							}

							i {
								display: none;

								@media (max-width: 767px) {
									display: block;
									font-size: 15px;
									color: #3a4597 !important;
								}
							}

							&:hover {
								color: #fff !important;
							}
						}

						&:before {
							content: " ";
							width: 2px;
							height: 25px;
							background: #e1e4e6;
							display: block;
							position: absolute;
							left: 0;
							top: 4px;

							@media (max-width: 991px) {
								height: 20px;
							}
						}
					}

					&.fav-count {
						margin-left: 0;
						padding-left: 15px;
						padding-right: 15px;

						@media (max-width: 991px) {
							margin-left: 0;
							padding-left: 7px;
							padding-right: 7px;
						}

						a {
							position: relative;
						}


						&:before {
							content: " ";
							width: 2px;
							height: 25px;
							background: #e1e4e6;
							display: block;
							position: absolute;
							left: 0;
							top: 4px;

							@media (max-width: 991px) {
								height: 20px;
							}
						}

						.sub-count {
							-moz-border-radius: 50%;
							-webkit-border-radius: 50%;
							border-radius: 50%;
							display: block;
							position: absolute;
							top: -5px;
							right: -14px;
							width: 18px;
							height: 18px;
							background: var(--color-primary);
							color: var(--color-white);
							text-align: center;
							font-size: 12px;
							line-height: 18px;

							@media (max-width: 1199px) {
								top: -25px;
							}
						}
					}
				}
			}
		}

	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}



	}

	.header-main {

		.header-logo {
			position: static;
			top: 10px;
			z-index: 20;

			.logo {
				width: 230px;
				height: auto;

				@media (max-width:1200px) {
					width: 200px;
				}

				@media (max-width:767px) {
					width: 100%;
					max-width: 180px;
				}
			}

		}

		.main-menu {
			float: right;
			position: absolute;
			left: 0;
			top: 100%;
			background-color: rgb(58 69 151 / 90%);
			padding: 50px 30px 30px 30px;

			@media (max-width: 991px) {
				padding: 0;
			}

			@media (max-width: 767px) {
				padding: 40px 0 0 0;
				overflow: auto;
				position: fixed;
				z-index: 99;
				top: 0;
				height: 100vh;
				background-color: rgb(58 69 151 / 95%);
			}


			>ul {
				padding: 0;
				margin: 0;
				max-width: 1370px;
				width: 100%;
				margin: 0 auto !important;

				>li {
					float: left;
					display: inline-block;
					width: 100%;

					/* @media (max-width:1200px) {
						display: block;
						width: 100%;
					} */

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}

			.menu-box {

				.d-xl-none {
					@media (min-width: 992px) {
						display: none !important;
					}
				}

				.container {
					@media (max-width: 767px) {
						max-width: 100%;
					}
				}

				.close-mob {
					display: none;

					@media (max-width: 767px) {
						display: block;
					}

					a {
						color: #fff;
						font-size: 30px;
						position: absolute;
						z-index: 1;
						right: 20px;
						top: 10px;
						text-decoration: none !important;
					}
				}

				.col-md-4 {
					flex: 0 0 20%;
					max-width: 20%;

					@media (max-width: 991px) {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}

				h4 {
					color: #fff;
					margin: 0 0 20px 0;

					a {
						color: #fff;
						text-decoration: none;
					}

					@media (max-width: 767px) {
						margin-bottom: 10px;
					}
				}

				ul {

					@media (max-width: 767px) {
						margin-bottom: 20px;
					}

					li {
						margin: 0 0 5px 0;

						a {
							color: #fff;
							font-weight: 300;
							text-decoration: none !important;

							&:hover {
								color: var(--color-secondary);
							}
						}
					}
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		/* display: none; */
		position: static;
		right: 10px;
		text-decoration: none;
		color: var(--color-primary);
		font-size: 22px;

		&:hover {
			color: var(--color-secondary);
		}

		@media (max-width: 767px) {
			font-size: 20px;
		}

		span {
			font-size: 20px;
			text-transform: uppercase;
			line-height: 64px;
			margin: 0 0 0 10px;

			@media (max-width: 767px) {
				font-size: 18px;
				margin: 0;
			}
		}
	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	.top-nav {
		padding: 10px 0 8px;
		text-align: right;


		.input-search-unit {
			display: none;
		}
	}

	.header-logo {
		padding-left: 0px !important;
	}

	.nav-row {
		min-height: 60px !important;
	}

	.icon {
		display: block;
		bottom: 20px;
	}

	.dropdown-menu {
		position: relative !important;
		transform: none !important;
		width: 100%;
		border-top: none;
	}

	.main-menu {
		background-color: var(--nav-bg-color);
		padding: 5px 0;
		z-index: 50;
		display: none;
		width: 100%;
		border-bottom: 1px solid #61847a;
	}

	.search-unit-ul {
		margin: 15px 0px 0px;

		@media (max-width: 991px) {
			margin: 30px 0 20px 0px;
		}

		.input-search-unit {
			.input-group {
				.form-control {
					@media (max-width: 574px) {
						height: 40px !important;
					}
				}
			}
		}
	}


	@media screen and (max-width: 1200px) {

		/* .top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: 20px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		} */
	}

	@media screen and (max-width: 767px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: center;

			}

			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				/* position: absolute;
				bottom: 30px;
				right: 10px; */
			}
		}

	}

	/* @media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	} */

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

	.d-xs-none {
		@media (max-width: 991px) {
			display: none !important;
		}
	}
}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	/* z-index: 200; */
	z-index: 49;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}