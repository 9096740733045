.hero-image-v1 {
	position: relative;
	height: 700px;
	margin-top: 105px;
	overflow: hidden;

	@media (max-width: 767px) {
		height: auto;
		margin-top: 85px;
	}

	@media all and (min-width: 768px) and (max-width: 1199px) {
		margin-top: 95px;
		height: 330px;
	}

	@media all and (min-width: 1200px) and (max-width: 1499px) {
		margin-top: 95px;
		height: 450px;
	}

	.picture {
		/* height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px;

		} */
		height: 100%;

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.video {

		width: 100%;
		height: auto;
		position: absolute;
		bottom: -100px;



		@media (max-width: 767px) {
			position: static;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			bottom: 0;
		}

		video {
			min-height: 100%;
			min-width: 100%;

			@media (max-width:574px) {
				position: relative;
				left: -120px;
			}

			@media (max-width:767px) {
				min-height: auto;
				height: 100%;
				min-width: 700px;
			}
		}
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 64vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 992px) {
			top: 52vh;
		}



		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 4vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 55px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 576px) {
					font-size: 2.5rem;
					line-height: 2.8rem;
				}

				@media (max-height: 600px) {
					font-size: 2.5rem;
					line-height: 2.5rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				@media (min-width: 1600px) {
					font-size: 2.3rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 1.7rem;
				}

				@media (max-width: 575px) {
					font-size: 1.2rem;
				}
			}
		}

	}
}